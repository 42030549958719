import { useEffect, useReducer } from "react";
import httpRequest from "../../helpers/http";
import { globalReducer } from "../../helpers/reducers";
import { NodeEdges, ShippingZoneType } from "../../types/global";

const initialState = {
  loading: true,
  errors: null,
  data: [],
};
/**
 * Retrieve fresh store shipping to avoid building entire websitre for shipping changes
 * @returns list of store shipping
 */
export default function useFreshShipping(storeShippingUrl: `https://${string}` | `http://${string}`, defaultValue: NodeEdges<ShippingZoneType>[] = []) {
  let _isMounted = true;
  // @ts-ignore
  initialState.data = defaultValue;
  const [state, shippingDispatch] = useReducer(globalReducer, initialState);

  useEffect(() => {
    const abortController = new AbortController();
    const loadShipping = async () => {
      const response = await httpRequest({
        url: storeShippingUrl,
        requestConfig: {
          signal: abortController.signal
        }
      });

      if (response.code === "success") {
        // transform received data to look like it come from graphql
        const freshShipping = response?.data.map((item: Record<string, string | object>) => ({
          node: {
            ...item,
            zzenz_id: item.id,
          }
        }));
        _isMounted && shippingDispatch({
          type: "SET_LIST_WITH_MESSAGE",
          payload: {
            message: null,
            data: freshShipping || []
          }
        });
      }
    }
    loadShipping();

    return () => {
      _isMounted = false;
      abortController.abort();
    }
  }, []);

  return [state] as const;
}